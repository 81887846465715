export const statusTypes = {
  MODERATE: 'moderate',
  BLOCKED: 'blocked',
  READY: 'ready',
  PENDING: 'pending',
  RUNNING: 'running',
  PAUSED: 'paused',
  FINISHED: 'finished',
};

export default {
  [statusTypes.MODERATE]: 'На модерации',
  [statusTypes.BLOCKED]: 'Заблокирована',
  [statusTypes.READY]: 'Ожидает старта',
  [statusTypes.PENDING]: 'Ожидает',
  [statusTypes.RUNNING]: 'Выполняется',
  [statusTypes.PAUSED]: 'Пауза',
  [statusTypes.FINISHED]: 'Завершено',
};
