import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import baseDefault from './modules/baseDefault';
import baseAutocall from './modules/baseAutocall';
import autocall from './modules/autocall';
import autocallGroup from './modules/autocallGroup';
import numbers from './modules/numbers';
import statistic from './modules/statistic';
import billing from './modules/billing';
import schedule from './modules/schedule';
import trashNumbers from './modules/trashNumbers';
import ivrSettings from './modules/ivr/ivrSettings';
import ivrTts from './modules/ivr/ivrTts';
import ivr from './modules/ivr/ivr';
import tts from './modules/ivr/tts';
import audio from './modules/audio';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessForbidden: false,
    devTime: false,
  },
  mutations: {
    DENY_ACCESS(state) {
      state.accessForbidden = true;
    },
    SET_DEV_TIME(state, value) {
      state.devTime = value;
    },
  },
  actions: {},
  getters: {
    ACCESS_FORBIDDEN: state => state.accessForbidden,
    IS_DEV_TIME: state => state.devTime,
  },
  modules: {
    auth,
    baseDefault,
    baseAutocall,
    autocall,
    autocallGroup,
    numbers,
    statistic,
    billing,
    schedule,
    trashNumbers,
    ivrSettings,
    ivrTts,
    ivr,
    tts,
    audio,
  },
});
