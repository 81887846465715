import axios from 'axios';
import { urlApiBilling } from '../config';

const instance = axios.create({
  baseURL: urlApiBilling,
  headers: {
    AUTHORIZATION: '879c1479-36f7-4966-902b-facc31455f88-879c1479-36f7-4966-902b-facc31455f88',
  },
});

export default instance;
