import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/notifications';
// import { statusTypes } from './mappers/statuses';
// import { DELAY_CYCLE_UPDATE_PROGRESS, DELAY_CYCLE_UPDATE_AUTH, DELAY_CYCLE_UPDATE_DEVTIME } from './config';
import { DELAY_CYCLE_UPDATE_PROGRESS, DELAY_CYCLE_UPDATE_DEVTIME } from './config';

Vue.config.productionTip = false;

let cycleUpdateProgress;
let cycleUpdateDevTime;
// let cycleUpdateAuth;
// const STATUSES_FOR_UPDATE_STATISTICS = [statusTypes.READY, statusTypes.RUNNING, statusTypes.PAUSED, statusTypes.FINISHED];

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    Promise.all([
      store.dispatch('GET_DEV_TIME'),
    ]).then(() => {
      if (store.getters.IS_DEV_TIME === false) {
        Promise.all([
          store.dispatch('AUTH'),
          store.dispatch('GET_GROUPS'),
          store.dispatch('GET_ALL_AUTOCALLS'),
          store.dispatch('GET_BASES'),
          // store.dispatch('GET_AUTOCALL_INFO'),
          store.dispatch('GET_TTS_VOICES'),
          store.dispatch('GET_ALL_AUTOCALL_PROGRESS'),
        ]);
        cycleUpdateProgress = setInterval(() => {
          store.dispatch('GET_ALL_AUTOCALL_PROGRESS');
        }, DELAY_CYCLE_UPDATE_PROGRESS);
        // if (store.getters.ACCESS_FORBIDDEN === false) {
        //   cycleUpdateAuth = setInterval(() => {
        //     store.dispatch('AUTH');
        //   }, DELAY_CYCLE_UPDATE_AUTH);
        // }
      }
    });
    cycleUpdateDevTime = setInterval(() => {
      store.dispatch('GET_DEV_TIME');
    }, DELAY_CYCLE_UPDATE_DEVTIME);
  },
  destroyed() {
    cycleUpdateProgress = clearInterval(cycleUpdateProgress);
    // cycleUpdateAuth = clearInterval(cycleUpdateAuth);
    cycleUpdateDevTime = clearInterval(cycleUpdateDevTime);
  },
}).$mount('#app');
