import httpAutocaller from '../../plugins/httpAutocaller';

const state = {
  groups: null,
  isLoadingGroups: true,
};

const mutations = {
  SET_GROUPS(state, groups) {
    state.groups = groups;
  },
  ADD_GROUP(state, group) {
    if (state.groups === null) {
      state.groups = [];
    }

    state.groups.push(group);
  },
  UPDATE_GROUP(state, group) {
    state.groups = state.groups.map(g => (g.id === group.id ? group : g));
  },
  DELETE_GROUP(state, id) {
    state.groups = state.groups.filter(g => g.id !== id);
  },
  SET_GROUP_LOADER(state, isLoading) {
    state.isLoadingGroups = isLoading;
  },
};

const actions = {
  /**
   * Получение всех группы рассылок
   * @method GET_GROUPS
   * @param {Vuex} store
   * @returns {Array} Массив списка групп
   */
  async GET_GROUPS({ commit }) {
    try {
      const { data: { results } } = await httpAutocaller.get('/groups/');

      commit('SET_GROUP_LOADER', false);

      commit('SET_GROUPS', results);

      return results;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Создание группы рассылок
   * @method CREATE_GROUP
   * @param {Vuex} store
   * @param {string} name - Название группы
   * @returns {Object} - Новая группа
   */
  async CREATE_GROUP({ commit }, name) {
    try {
      const { data } = await httpAutocaller.post('/groups/', { name });

      commit('ADD_GROUP', data);

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получение детальной информации по группе рассылок
   * @method CREATE_GROUP
   * @param {Vuex} store
   * @param {number} id - ID группы
   */
  async GET_GROUP_INFO(store, id) {
    try {
      const { data: { results } } = await httpAutocaller.get(`/groups/${id}/`);

      return results;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Обновление названия группы рассылок
   * @method UPDATE_NAME_GROUP
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.id - ID группы
   * @param {string} _.name - Название группы
   */
  async UPDATE_NAME_GROUP({ commit }, { id, name }) {
    try {
      const { data } = await httpAutocaller.put(`/groups/${id}/`, { name });

      commit('UPDATE_GROUP', data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Удаление группы рассылок по ID
   * @method DELETE_GROUP
   * @param {Vuex} store
   * @param {number} id - ID группы
   */
  async DELETE_GROUP({ commit }, id) {
    try {
      const { status } = await httpAutocaller.delete(`/groups/${id}/`);

      if (status === 204) {
        commit('DELETE_GROUP', id);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  GROUPS: state => state.groups,
  IS_LOADING_GROUPS: state => state.isLoadingGroups,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
