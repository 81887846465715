import httpAutocaller from '../../../plugins/httpAutocaller';

const state = {
  ivr: [],
};

const mutations = {
  ADD_IVR(state, ivr) {
    state.ivr.push(ivr);
  },
  UPDATE_IVR(state, ivr) {
    state.ivr = state.ivr.map(item => (item.id === ivr.id ? ivr : item));
  },
  DELETE_IVR(state, ivrId) {
    state.ivr = state.ivr.filter(({ id }) => id !== ivrId);
  },
};

const actions = {
  /**
   * Добавление IVR рассылке
   * @method ADD_IVR
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.num - Внутренний номер IVR
   */
  async ADD_IVR({ commit }, { groupId, autocallId, num }) {
    try {
      const { data } = await httpAutocaller.post(`/groups/${groupId}/auto_call/${autocallId}/ivr/`, { num });

      if (data) {
        commit('ADD_IVR', data);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Получение прикрепленных IVR в рассылке
   * @method GET_IVR
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @return {Object} - IVR
   */
  async GET_IVR({ commit, getters }, { groupId, autocallId }) {
    try {
      if (getters.IVR_BY_AUTOCALL_ID(autocallId)) {
        return getters.IVR_BY_AUTOCALL_ID(autocallId);
      }

      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/ivr/`);

      if (data.results.length) {
        commit('ADD_IVR', data.results[data.results.length - 1]);

        return data.results[data.results.length - 1];
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Обновление IVR в рассылке
   * @method UPDATE_IVR_TTS
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.ivrId - ID IVR
   * @param {number} _.num - Внутренний номер IVR
   */
  async UPDATE_IVR({ commit }, { groupId, autocallId, ivrId, num }) {
    try {
      const { data } = await httpAutocaller.put(`/groups/${groupId}/auto_call/${autocallId}/ivr/${ivrId}/`, { num });

      if (data) {
        commit('UPDATE_IVR', data);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Удаление IVR из рассылки
   * @method DELETE_IVR
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.ivrId - ID IVR
   */
  async DELETE_IVR({ commit }, { groupId, autocallId, ivrId }) {
    try {
      const { status } = await httpAutocaller.delete(`/groups/${groupId}/auto_call/${autocallId}/ivr/${ivrId}/`);

      if (status === 204) {
        commit('DELETE_IVR', ivrId);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  IVR_BY_AUTOCALL_ID: state => callingId => state.ivr.find(({ calling }) => calling === callingId),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
