import axios from 'axios';
import fileDownload from 'js-file-download';
import httpAutocaller from '../../plugins/httpAutocaller';
import store from '../index';

const state = {
  isLoadingBases: true,
  bases: [],
};

const mutations = {
  SET_BASES(state, bases) {
    state.bases = bases;
  },
  ADD_BASE(state, base) {
    state.bases.push(base);
  },
  UPDATE_BASE(state, base) {
    state.bases = state.bases.map(b => (b.id === base.id ? base : b));
  },
  DELETE_BASE(state, id) {
    state.bases = state.bases.filter(b => b.id !== id);
  },
  SET_BASE_LOADER(state, isLoading) {
    state.isLoadingBases = isLoading;
  },
};

const actions = {
  /**
   * Получение всех баз номеров
   * @method GET_BASES
   */
  async GET_BASES({ commit, dispatch }) {
    try {
      const { data: { results } } = await httpAutocaller.get('/bases/');

      await Promise.all(results.map(base => dispatch('GET_NUMBERS_FROM_BASE', { baseId: base.id })));

      commit('SET_BASES', results);

      commit('SET_BASE_LOADER', false);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Создание базы номеров
   * @method CREATE_BASE
   * @param {Vuex} store
   * @param {Object} base - База контактов
   * @param {string} base.name - Название базы
   * @param {string} base.comment - Комментарий к базе
   */
  async CREATE_BASE({ commit }, { name, comment }) {
    try {
      const { data } = await httpAutocaller.post('/bases/', { name, comment });

      commit('ADD_BASE', data);

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получение информации по базе
   * @method
   * @param {Vuex} store
   * @param {number} id - ID базы
   */
  async GET_INFORMATION_ON_BASE({ commit }, { id }) {
    try {
      const { data } = await httpAutocaller.get(`/bases/${id}/`);
      commit('ADD_BASE', data);

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Обновление информации в базе
   * @method UPDATE_BASE
   * @param {Vuex} store
   * @param {Object} base - База контактов
   * @param {number} base.id - ID базы
   * @param {string} base.name - Название базы
   * @param {string} base.comment - Комментарий к базе
   */
  async UPDATE_BASE({ commit }, { id, name, comment }) {
    try {
      const { data } = await httpAutocaller.put(`/bases/${id}/`, { name, comment });

      commit('UPDATE_BASE', data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Удаление базы
   * @method DELETE_BASE
   * @param {Vuex} store
   * @param {number} id - ID базы
   */
  async DELETE_BASE({ commit }, id) {
    try {
      const { status } = await httpAutocaller.delete(`/bases/${id}/`);

      if (status === 204) {
        commit('DELETE_BASE', id);
        store.commit('UNSET_NUMBERS', id);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получение списка номеров в базе, в виде excel файла
   * @method DOWNLOAD_BASE_AS_EXEL
   * @param {Vuex} store
   * @param {number} id - ID базы
   */
  async DOWNLOAD_BASE_AS_EXCEL(store, id) {
    try {
      const { data, headers } = await httpAutocaller.get(`/bases/${id}/excel/`, {
        responseType: 'blob',
        transformResponse: [
          ...axios.defaults.transformResponse,
          data => data,
        ],
      });

      const [, fileName] = headers['content-disposition'].match(/filename="(.*?)"/);

      fileDownload(data, fileName);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async IMPORT_NUMBERS_TO_CONTACT_LIST({ dispatch }, data) {
    await httpAutocaller.post(`/bases/${data.id}/add_list_of_numbers_with_fields/`, data.data);
    await dispatch('GET_NUMBERS_FROM_BASE', { baseId: data.id });
  },
};

const getters = {
  IS_LOADING_BASES: state => state.isLoadingBases,
  BASES: state => state.bases.map(base => {
    const numbers = store.getters.NUMBERS.find(n => n.baseId === base.id) || {};

    return {
      ...base,
      ...numbers,
    };
  }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
