import httpAutocaller from '../../../plugins/httpAutocaller';

const state = {
  tts: [],
  voices: [],
};

const mutations = {
  ADD_TTS_PHRASE(state, tts) {
    state.tts.push(tts);
  },
  UPDATE_TTS_PHRASE(state, tts) {
    state.tts = state.tts.map(item => (item.id === tts.id ? tts : item));
  },
  DELETE_TTS_PHRASE(state, ttsId) {
    state.tts = state.tts.filter(({ id }) => id !== ttsId);
  },
  SET_VOICES(state, voices) {
    state.voices = voices;
  },
};

const actions = {
  /**
 * Добавление TTS фразы к рассылке
 * @method GENERATE_TTS
 * @param {Vuex} store
 * @param {string} _.phrase - Фраза для генерации TTS
 * @param {string} _.voice - Голос для генерации TTS
 * @param {string} _.emotion - Эмоция для генерации TTS
 * @param {float} _.speed - Скорость TTS
 */
  async GENERATE_TTS(context, { phrase, voice, emotion, speed }) {
    try {
      const { data } = await httpAutocaller.post('/generate_tts/', { phrase, voice, emotion, speed });

      if (data) {
        return data;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Добавление TTS фразы к рассылке
   * @method ADD_TTS_PHRASE
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {string} _.phrase - Фраза для генерации TTS
   * @param {string} _.voice - Голос для генерации TTS
   * @param {string} _.emotion - Эмоция для генерации TTS
   * @param {float} _.speed - Скорость TTS
   */
  async ADD_TTS_PHRASE({ commit }, { groupId, autocallId, phrase, voice, emotion, speed }) {
    try {
      const { data } = await httpAutocaller.post(`/groups/${groupId}/auto_call/${autocallId}/tts/`, { phrase, voice, emotion, speed });

      if (data) {
        commit('ADD_TTS_PHRASE', data);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Получение TTS фразы у рассылки
   * @method GET_TTS_PHRASE
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @return {Object} - tts
   */
  async GET_TTS_PHRASE({ commit, getters }, { groupId, autocallId }) {
    try {
      if (getters.TTS_BY_AUTOCALL_ID(autocallId)) {
        return getters.TTS_BY_AUTOCALL_ID(autocallId);
      }

      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/tts/`);

      if (data.results.length) {
        const tts = { calling: +autocallId, ...data.results[data.results.length - 1] };

        commit('ADD_TTS_PHRASE', tts);

        return tts;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Обновление TTS фразы в рассылке
   * @method UPDATE_TTS_PHRASE
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.ttsId - ID tts фразы прикрепленной к рассылке
   * @param {string} _.phrase - Фраза для генерации TTS
   * @param {string} _.voice - Голос для генерации TTS
   * @param {string} _.emotion - Эмоция для генерации TTS
   * @param {float} _.speed - Скорость TTS
   */
  async UPDATE_TTS_PHRASE({ commit }, { groupId, autocallId, ttsId, phrase, voice, emotion, speed }) {
    try {
      const { data } = await httpAutocaller.put(`/groups/${groupId}/auto_call/${autocallId}/tts/${ttsId}/`, { phrase, voice, emotion, speed });

      if (data) {
        commit('UPDATE_TTS_PHRASE', { calling: autocallId, ...data });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Удаление TTS фразы у рассылки
   * @method DELETE_TTS_PHRASE
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.ttsId - ID tts фразы прикрепленной к рассылке
   */
  async DELETE_TTS_PHRASE({ commit }, { groupId, autocallId, ttsId }) {
    try {
      const { status } = await httpAutocaller.delete(`/groups/${groupId}/auto_call/${autocallId}/tts/${ttsId}/`);

      if (status === 204) {
        commit('DELETE_TTS_PHRASE', ttsId);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Обновление TTS фразы в рассылке
   * @method GET_TTS_VOICES
   * @param {Vuex} store
   * @param {Object} _
   * @return {Object} - tts voices
   */
  async GET_TTS_VOICES({ commit }) {
    try {
      const { data } = await httpAutocaller.get('/get_tts_voices/');

      if (data) {
        commit('SET_VOICES', data);
        return data;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  TTS_BY_AUTOCALL_ID: state => id => state.tts.find(({ calling }) => calling === id),
  TTS_GET_VOICES: state => state.voices,
  TTS_GET_VOICE_EMOTIONS: state => voice => state.voices.filter(voiceObj => voiceObj.voice === voice),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
