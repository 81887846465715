import httpAutocaller from '../../plugins/httpAutocaller';

const state = {
  weekDaysName: [
    { id: 0, name: 'mo', label: 'пн' },
    { id: 1, name: 'tu', label: 'вт' },
    { id: 2, name: 'we', label: 'ср' },
    { id: 3, name: 'th', label: 'чт' },
    { id: 4, name: 'fr', label: 'пт' },
    { id: 5, name: 'sa', label: 'сб' },
    { id: 6, name: 'su', label: 'вс' },
  ],
  periods: [
    { id: 'none', label: 'Не повторять' },
    { id: 'loop', label: 'В цикле' },
  ],
};

const mutations = {

};

const actions = {
  /**
   * Создание и подключение расписание к рассылке
   * @method CREATE_SCHEDULE
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @param {Object} _.schedule - Настройки расписания
   * @param {string} _.schedule.expirationDatetime - Время истечения рассылки. Формат: 2021-05-09T16:00:45.698Z
   * @param {string} _.schedule.exceptions - Даты исключения. Формат: "2021-05-10, 2021-05-25"
   * @param {number} _.schedule.autoRestart - Периодичность. Типы: 0 - ежедневно, 1 - еженедельно, 2 - ежемесячно
   * @param {string} _.schedule.start - Время начала звонков. Формат: 08.00.00
   * @param {string} _.schedule.stop - Время окончания звонков. Формат: 08.00.00
   * @param {number} _.schedule.tz - Часовой пояс
   * @param {boolean=} [_.schedule.mo=false] - Совершать ли звонки в понедельник?
   * @param {boolean=} [_.schedule.tu=false] - Совершать ли звонки в вторник?
   * @param {boolean=} [_.schedule.we=false] - Совершать ли звонки в среду?
   * @param {boolean=} [_.schedule.th=false] - Совершать ли звонки в четверг?
   * @param {boolean=} [_.schedule.fr=false] - Совершать ли звонки в пятницу?
   * @param {boolean=} [_.schedule.sa=false] - Совершать ли звонки в субботу?
   * @param {boolean=} [_.schedule.su=false] - Совершать ли звонки в воскресенье?
   *
   */
  async CREATE_SCHEDULE({ getters }, { groupId, autocallId, schedule }) {
    try {
      const { data } = await httpAutocaller.post(`/groups/${groupId}/auto_call/${autocallId}/call_time/`, {
        ...Object.fromEntries(getters.WEEK_DAYS_NAME.map(day => ([[day.name], false]))),
        ...schedule,
      });

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Обновление расписания в рассылке
   * @method UPDATE_SCHEDULE
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @param {Object} _.schedule - Настройки расписания
   * @param {number} _.schedule.id - ID расписания
   * @param {string=} _.schedule.expirationDatetime - Время истечения рассылки. Формат: 2021-05-09T16:00:45.698Z
   * @param {string=} _.schedule.exceptions - Даты исключения. Формат: "2021-05-10, 2021-05-25"
   * @param {number=} _.schedule.autoRestart - Периодичность.
   * @param {number=} _.schedule.countLoop - кол-во циклов
   * @param {string=} _.schedule.start - Время начала звонков. Формат: 08.00.00
   * @param {string=} _.schedule.stop - Время окончания звонков. Формат: 08.00.00
   * @param {number=} _.schedule.tz - Часовой пояс
   * @param {boolean=} _.schedule.mo - Совершать ли звонки в понедельник?
   * @param {boolean=} _.schedule.tu - Совершать ли звонки в вторник?
   * @param {boolean=} _.schedule.we - Совершать ли звонки в среду?
   * @param {boolean=} _.schedule.th - Совершать ли звонки в четверг?
   * @param {boolean=} _.schedule.fr - Совершать ли звонки в пятницу?
   * @param {boolean=} _.schedule.sa - Совершать ли звонки в субботу?
   * @param {boolean=} _.schedule.su - Совершать ли звонки в воскресенье?
   *
   */
  async UPDATE_SCHEDULE(store, { groupId, autocallId, schedule }) {
    try {
      const { data } = await httpAutocaller.put(`/groups/${groupId}/auto_call/${autocallId}/call_time/${schedule.id}/`, { ...schedule });

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Получение расписания в рассылке
   * @method GET_SCHEDULES
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @returns {Object} Расписание
   */
  async GET_SCHEDULE(store, { groupId, autocallId }) {
    try {
      const { data: { results } } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/call_time/`);

      return results[0] ?? {};
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Получение настроек расписания в рассылке
   * @method GET_SETTINGS_SCHEDULE
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.scheduleId - ID расписания
   *
   */
  async GET_SETTINGS_SCHEDULE(store, { groupId, autocallId, scheduleId }) {
    try {
      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/call_time/${scheduleId}/`);

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Удаление расписания в рассылке
   * @method DELETE_SCHEDULE
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.scheduleId - ID расписания
   *
   */
  async DELETE_SCHEDULE(store, { groupId, autocallId, scheduleId }) {
    try {
      await httpAutocaller.delete(`/groups/${groupId}/auto_call/${autocallId}/call_time/${scheduleId}/`);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  WEEK_DAYS_NAME: state => state.weekDaysName,
  PERIODS: state => state.periods,
  GET_PERIOD_ID_BY_INDEX: state => i => state.periods[i].id,
  GET_PERIOD_LABEL_BY_INDEX: state => i => state.periods[i].label,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
