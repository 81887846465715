<template>
  <v-dialog v-model="IS_DEV_TIME" persistent max-width="400px">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        Технические работы!
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center">
        <v-icon x-large class="icon mb-3" color="accent">mdi-run</v-icon>
        <v-icon x-large class="icon mb-3" color="warning">mdi-carrot</v-icon>
        <h4 class="text-subtitle-2">Мы внедряем новые фичи</h4>
        <v-spacer />
        <h4 class="text-subtitle-2"><strong>Все запущенные кампании работают в штатном режиме!</strong></h4>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DialogDevTime',
  computed: {
    ...mapGetters(['IS_DEV_TIME']),
  },
};
</script>
