import axios from 'axios';
import humps from 'humps';
import store from '../store';
import { urlTelezonLk } from '../config';

const instance = axios.create({
  baseURL: urlTelezonLk,
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data),
  ],
  transformRequest: [
    data => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest,
  ],
});

export default instance;

// if (process.env.NODE_ENV !== 'development') {
//   instance.interceptors.response.use(response => response, error => {
//     if (error.response.status === 403) {
//       store.commit('DENY_ACCESS');
//     }

//     return Promise.reject(data);
//   });
// }

instance.interceptors.response.use(response => response, error => {
  if (error.response.status !== 200) {
    store.commit('DENY_ACCESS');
  }

  return Promise.reject(data);
});
