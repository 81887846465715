import axios from 'axios';
import httpAutocaller from '../../plugins/httpAutocaller';

const state = {
  audio: [],
};

const mutations = {
  ADD_AUDIO(state, audio) {
    state.audio.push(audio);
  },
  UPDATE_AUDIO(state, audio) {
    state.audio = state.audio.map(a => (a.id === audio.id ? audio : a));
  },
  DELETE_AUDIO(state, audioId) {
    state.audio = state.audio.filter(({ id }) => id !== audioId);
  },
};

const actions = {
  /**
   * Получение аудио у автодозвона
   * @method GET_AUDIO_BY_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   */
  async GET_AUDIO_BY_AUTOCALL({ commit, getters }, { groupId, autocallId }) {
    try {
      if (getters.GET_AUDIO_BY_AUTOCALL_ID(autocallId)) {
        return getters.GET_AUDIO_BY_AUTOCALL_ID(autocallId);
      }

      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/audio/`);

      if (data.results.length) {
        commit('ADD_AUDIO', { ...data.results[data.results.length - 1], calling: autocallId });

        return { ...data.results[data.results.length - 1], calling: autocallId };
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Добавление аудио в автодозвон
   * @method ADD_AUDIO_IN_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {Object} _.audio - Аудиофайл
   */
  async ADD_AUDIO_IN_AUTOCALL({ commit }, { groupId, autocallId, audio }) {
    try {
      const formData = new FormData();

      formData.append('file', audio.file);

      const { data } = await httpAutocaller.post(`/groups/${groupId}/auto_call/${autocallId}/audio/`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        transformRequest: [
          ...axios.defaults.transformRequest,
          data => data,
        ],
      });

      if (data) {
        commit('ADD_AUDIO', { ...data, calling: autocallId });

        return { ...data, calling: autocallId };
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Обновление аудиофайла у рассылки
   * @method UPDATE_AUDIO_IN_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.audioId - ID прикрепленного аудио
   * @param {Object} _.audio - Аудиофайл
   */
  async UPDATE_AUDIO_IN_AUTOCALL({ commit }, {
    groupId,
    autocallId,
    audio,
  }) {
    try {
      const formData = new FormData();

      formData.append('file', audio.file);

      const { data } = await httpAutocaller.put(`/groups/${groupId}/auto_call/${autocallId}/audio/${audio.id}/`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        transformRequest: [
          ...axios.defaults.transformRequest,
          data => data,
        ],
      });

      if (data.update === 'success') {
        commit('UPDATE_AUDIO', { ...audio, name: audio.file.name });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Удаление аудиофайла у автодозвона
   * @method DELETE_AUDIO_IN_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.audioId - ID прикрепленного аудио
   */
  async DELETE_AUDIO_IN_AUTOCALL({ commit }, {
    groupId,
    autocallId,
    audioId,
  }) {
    try {
      const { status } = await httpAutocaller.delete(`/groups/${groupId}/auto_call/${autocallId}/audio/${audioId}/`);

      if (status === 204) {
        commit('DELETE_AUDIO', audioId);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  GET_AUDIO_BY_AUTOCALL_ID: state => id => state.audio.find(({ calling }) => +calling === +id),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
