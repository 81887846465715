import humps from 'humps';
import httpAutocaller from '../../plugins/httpAutocaller';
import httpTelezon from '../../plugins/httpTelezon';
import { getUrlSearchParams } from '../../helpers';

const { token, pin } = humps.camelizeKeys(getUrlSearchParams());

const state = {
  pin: localStorage.getItem('pin') || pin || null,
  token: localStorage.getItem('token') || token || null,
};

const mutations = {
  SET_PIN(state, pin) {
    state.pin = pin;
    httpAutocaller.defaults.headers.common.PIN = pin;
    localStorage.setItem('pin', pin);
  },
  SET_TOKEN(state, token) {
    state.token = token;
    httpAutocaller.defaults.headers.common.AUTHORIZATION = token;
    localStorage.setItem('token', token);
  },
};

const actions = {
  /**
   * Получение API UUID для остальных запросов
   * @method AUTH
   * @param {Vuex} store
   */
  async AUTH({ getters, commit }) {
    try {
      const { data } = await httpTelezon.post('/auth/token-update/', { auth: { login: getters.PIN, name: 'Token', token: getters.TOKEN } });
      commit('SET_TOKEN', JSON.parse(data).token);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  PIN: state => state.pin,
  TOKEN: state => state.token,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
