import httpAutocaller from '../../plugins/httpAutocaller';

const state = {
  userExternalNumbers: [],
  trashNumbers: [],
};

const mutations = {
  SET_EXTERNAL_NUMBERS(state, numbers) {
    state.userExternalNumbers = numbers.map(n => n.number);
  },
  SET_TRASH_NUMBERS(state, numbers) {
    state.trashNumbers = numbers.map(n => n.number);
  },
};

const actions = {
  /**
   * Получение всех списков номеров для подстановки в рассылку
   * @method GET_TRASH_NUMBERS
   * @param {Vuex} store
   */
  async GET_TRASH_NUMBERS({ commit, getters }) {
    try {
      const { data: { results } } = await httpAutocaller.get('/trash_numbers/');

      commit('SET_TRASH_NUMBERS', results);

      const { data } = await httpAutocaller.post('/get_external_numbers/', {
        pin: getters.PIN,
      });

      commit('SET_EXTERNAL_NUMBERS', data);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {

};

export default {
  state,
  mutations,
  actions,
  getters,
};
