<template>
  <header class="elevation-1 app-header">
    <v-container>
      <v-row>
        <v-col cols="12" xl="10" offset-xl="1">
          <v-container>
            <v-row no-gutters class="d-flex align-center">
              <div>
                <span class="d-flex align-start justify-start">
                  <router-link to="/" class="text-decoration-none">
                    <h2 class="text-h4">Сервис голосовых уведомлений</h2>
                  </router-link>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn rounded v-bind="attrs" icon v-on="on" class="ml-1" x-small><v-icon>mdi-help-circle</v-icon></v-btn>
                    </template>
                    <div class="text-caption tooltip">
                      Сервис голосовых уведомлений позволяет генерировать
                      аудиосообщения текста или загружать, созданные диктором.
                      <br>
                      И автоматически рассылать их своим клиентам:
                      <ul>
                        <li>информируя об акциях,</li>
                        <li>напоминая о событиях,</li>
                        <li>проводя опросы,</li>
                        <li>поздравляя с праздниками</li>
                        <li>или донося любую другую информацию</li>
                      </ul>
                      Вы можете настроить соединение клиента с сотрудником кампании
                    </div>
                  </v-tooltip>
                </span>

                <!-- <div v-if="!loading">
                  Пакет минут: <span class="text-decoration-underline">{{ AUTOCALL_PACKAGE_NAME && !ACCESS_FORBIDDEN ? AUTOCALL_PACKAGE_NAME : '-' }}</span>
                </div>
                <v-skeleton-loader v-else type="text" width="150"/> -->
              </div>

              <!-- <v-spacer /> -->

              <!-- <v-chip v-if="!loading" class="tariff text-center pa-3" color="primary">
                <span v-if="AUTOCALL_LOST_MINUTES && !ACCESS_FORBIDDEN">{{ lostMinutesText }} доступно</span>
              </v-chip>
              <v-skeleton-loader v-else class="tariff" type="image"/> -->
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { plural } from '../helpers';

export default {
  name: 'AppHeader',
  computed: {
    ...mapState({
      loading: state => state.billing.loadingAutocallInfo,
    }),
    ...mapGetters([
      'AUTOCALL_LOST_MINUTES',
      'AUTOCALL_PACKAGE_NAME',
      'AUTOCALL_TARIFF_NAME',
      'ACCESS_FORBIDDEN',
    ]),
    lostMinutesText() {
      return plural(this.AUTOCALL_LOST_MINUTES, ['минута', 'минуты', 'минут']);
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  max-width: 450px;
}

.tariff {
  &.v-chip {
    width: 100px !important;
    height: 100px !important;
    background: #26a33b;
    border-radius: 50% !important;
    color: #fff;
    line-height: 1.4;
    white-space: initial;
  }

  .v-skeleton-loader__image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}
</style>
