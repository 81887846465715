import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "table-autocalls" */ '../views/table-autocalls/Index.vue'),
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import(/* webpackChunkName: "groups" */ '../views/Groups.vue'),
  },
  {
    path: '/contacts-list',
    component: () => import(/* webpackChunkName: "table-autocalls" */ '../views/contact-list/Index.vue'),
    children: [
      {
        path: '',
        name: 'contactsList',
        component: () => import(/* webpackChunkName: "table-autocalls" */ '../views/contact-list/ContactsListTable.vue'),
      },
      {
        path: ':baseId',
        name: 'contactsList.contact',
        component: () => import(/* webpackChunkName: "table-autocalls" */ '../views/contact-list/ContactsListTableItem.vue'),
      },
    ],
  },
  {
    path: '/create-autocall',
    name: 'createAutocall',
    component: () => import(/* webpackChunkName: "autocall" */ '../views/manager-autocall/Create.vue'),
  },
  {
    path: '/autocalls/:groupId-:autocallId',
    name: 'editAutocall',
    component: () => import(/* webpackChunkName: "autocall" */ '../views/manager-autocall/Edit.vue'),
  },
  {
    path: '/statistic',
    name: 'statistic',
    component: () => import(/* webpackChunkName: "statistic" */ '../views/statistic/Statistic.vue'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/',
  fallback: false,
});

// @todo Позже нужно переделать, возможно убрать даже перенос пина со страницы на страницу
router.beforeEach((to, from, next) => {
  if (from.query.pin && !to.query.pin) {
    if (to.path === from.path) {
      return;
    }

    next({ ...to, query: { ...to.query, pin: from.query.pin } });
  } else {
    next();
  }
});

// метод router.push не понимает редиректа с сохранением параметра запроса и выкидывает ошибку
const originalPush = router.push;

router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch(err => {
    if (/Redirected when going from/.test(err)) {
      return Promise.resolve(false);
    }

    return Promise.reject(err);
  });
};

export default router;
