export function plural(num, words) {
  const remainder = Math.abs(num) % 100;
  const num1 = remainder % 10;

  if (remainder > 10 && remainder < 20) {
    return `${num} ${words[2]}`;
  }

  if (num1 > 1 && num1 < 5) {
    return `${num} ${words[1]}`;
  }

  if (num1 === 1) {
    return `${num} ${words[0]}`;
  }

  return `${num} ${words[2]}`;
}

export function getUrlSearchParams() {
  const str = window.location.search.substr(1);

  const params = str.split('&').map(row => row.split('='));

  return Object.fromEntries(params);
}
