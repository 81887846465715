import httpAutocaller from '../../plugins/httpAutocaller';
import { statusTypes } from '../../mappers/statuses';
import store from '..';

const state = {
  isLoadingAutocalls: true,
  autocalls: [],
};

const mutations = {
  SET_AUTOCALLS(state, autocalls) {
    state.autocalls = autocalls;
  },
  SET_AUTOCALL_LOADER(state, isLoading) {
    state.isLoadingAutocalls = isLoading;
  },
  ADD_AUTOCALL(state, autocall) {
    state.autocalls.push(autocall);
  },
  DELETE_AUTOCALLS(state, autocallId) {
    state.autocalls = state.autocalls.filter(a => a.id !== autocallId);
  },
  UPDATE_AUTOCALL(state, { autocallId, fields }) {
    state.autocalls = state.autocalls.map(a => (a.id === autocallId ? { ...a, ...fields } : a));
  },
};

const actions = {
  /**
   * Получение рассылок во всех группах
   * @method GET_ALL_AUTOCALLS
   * @param {Vuex} store
   */
  async GET_ALL_AUTOCALLS({ commit, dispatch }) {
    try {
      const { data: { results } } = await httpAutocaller.get('/all_dispatch/');

      const autocalls = await Promise.all(
        results.map(async autocall => {
          await Promise.all([
            dispatch('GET_BASES_IN_AUTOCALL', { groupId: autocall.group, autocallId: autocall.id }),
            // dispatch('GET_AUTOCALL_PROGRESS', { groupId: autocall.group, autocallId: autocall.id }),
          ]);

          return autocall;
        }),
      );

      commit('SET_AUTOCALLS', autocalls);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit('SET_AUTOCALL_LOADER', false);
    }
  },

  /**
 * Получение рассылок во всех группах
 * @method GET_DEV_TIME
 * @param {Vuex} store
 */
  async GET_DEV_TIME() {
    try {
      const { data } = await httpAutocaller.get('/is_dev_time/');
      store.commit('SET_DEV_TIME', data.devTime);
    } catch (e) {
      console.log(e);
      store.commit('SET_DEV_TIME', true);
      throw e;
    }
  },

  /**
   * Получение списка рассылок в группе по id
   * @method GET_AUTOCALLS_IN_GROUP
   * @param store
   * @param {number} groupId - ID группы
   * @returns {Array} Массив рассылок в группе
   */
  async GET_AUTOCALLS_IN_GROUP(store, groupId) {
    try {
      const { data: { results } } = await httpAutocaller.get(`/groups/${groupId}/auto_call/`);

      return results;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Создание рассылки
   * @method CREATE_AUTOCALL
   * @param {Vuex} store
   * @param {Object} autocall - Рассылка
   * @param {string} autocall.dateStart - Дата начала рассылки. Формат: 2021-04-14T08:07:57.942Z
   * @param {string} autocall.name - Название рассылки
   * @param {string} autocall.comment - Комментарий
   * @param {number} autocall.speed - Количество вызовов в 1 час
   * @param {number} autocall.group - ID группы, к которой привязана рассылка
   */
  async CREATE_AUTOCALL({ commit }, autocall) {
    try {
      const { data } = await httpAutocaller.post(`/groups/${autocall.group}/auto_call/`, {
        ...autocall,
      });

      commit('ADD_AUTOCALL', data);

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Обновление рассылки
   * @method UPDATE_AUTOCALL
   * @param {Vuex} store
   * @param {Object} autocall - Рассылка
   * @param {number} autocall.id - ID рассылки
   * @param {string} autocall.dateStart - Дата начала рассылки. Формат: 2021-04-14T08:07:57.942Z
   * @param {string} autocall.name - Название рассылки
   * @param {string} autocall.comment - Комментарий
   * @param {number} autocall.speed - Количество вызовов в 1 час
   * @param {number} autocall.group - ID группы, к которой привязана рассылка
   */
  async UPDATE_AUTOCALL({ commit }, autocall) {
    try {
      const { data } = await httpAutocaller.put(`/groups/${autocall.group}/auto_call/${autocall.id}/`, {
        ...autocall,
      });

      commit('UPDATE_AUTOCALL', { autocallId: data.id, fields: data });

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Удаление рассылки
   * @method DELETE_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы
   * @param {number} _.autocallId - ID рассылки
   */
  async DELETE_AUTOCALL({ commit }, { groupId, autocallId }) {
    try {
      const { status } = await httpAutocaller.delete(`/groups/${groupId}/auto_call/${autocallId}/`);

      if (status === 204) {
        commit('DELETE_AUTOCALLS', autocallId);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Постановка на паузу рассылки
   * @method PAUSE_AUTOCALL
   * @param {Vuex} store
   * @param {Vuex} store
   * @param {number} _.groupId - ID группы
   * @param {number} _.autocallId - ID рассылки
   */
  async PAUSE_AUTOCALL({ commit }, { groupId, autocallId }) {
    try {
      const { data: { id, statusError } } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/pause/`);

      if (statusError) {
        throw statusError;
      }

      if (id) {
        commit('UPDATE_STATUS', { autocallId, status: statusTypes.PAUSED });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Старт рассылки
   * @method START_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы
   * @param {number} _.autocallId - ID рассылки
   */
  async START_AUTOCALL({ commit }, { groupId, autocallId }) {
    try {
      const { data: { id, statusError } } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/start/`);

      if (statusError) {
        throw statusError;
      }

      if (id) {
        commit('UPDATE_STATUS', { autocallId, status: statusTypes.RUNNING });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Возобновление рассылки
   * @method RESUME_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы
   * @param {number} _.autocallId - ID рассылки
   */
  async RESUME_AUTOCALL({ commit }, { groupId, autocallId }) {
    try {
      const { data: { id, statusError } } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/resume/`);

      if (statusError) {
        throw statusError;
      }

      if (id) {
        commit('UPDATE_STATUS', { autocallId, status: statusTypes.PENDING });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Остановить рассылку
   * @method STOP_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы
   * @param {number} _.autocallId - ID рассылки
   */
  async STOP_AUTOCALL({ commit }, { groupId, autocallId }) {
    try {
      const { data: { id, statusError } } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/stop/`);

      if (statusError) {
        throw statusError;
      }

      if (id) {
        commit('UPDATE_STATUS', { autocallId, status: statusTypes.READY });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получить настройки рассылки
   * @method GET_SETTINGS_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы
   * @param {number} _.autocallId - ID рассылки
   */
  async GET_SETTINGS_AUTOCALL(store, { groupId, autocallId }) {
    try {
      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/`);

      if (data?.statusError) {
        throw data.statusError;
      }
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Постановка на паузу рассылки
   * @method CREATE_AUTOCALL_FROM_UNCALLS
   * @param {Vuex} store
   * @param {Vuex} store
   * @param {number} _.groupId - ID группы
   * @param {number} _.autocallId - ID рассылки
   */
  async CREATE_AUTOCALL_FROM_UNCALLS(store, { groupId, autocallId }) {
    try {
      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/un_calls/`);

      if (data?.statusError) {
        throw data.statusError;
      }
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * INFO!
   * @method GET_INFORMATION_ON_AUTOCALL
   * @param {Vuex} store
   * @param {Vuex} store
   * @param {number} _.groupId - ID группы
   * @param {number} _.autocallId - ID рассылки
   */
  async GET_INFORMATION_ON_AUTOCALL({ commit }, { groupId, autocallId }) {
    try {
      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/`);

      if (data?.statusError) {
        throw data.statusError;
      }

      commit('ADD_AUTOCALL', data);

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  AUTOCALLS: state => state.autocalls,
  GET_AUTOCALL_BY_ID: state => autocallId => state.autocalls.find(a => a.id === +autocallId),
  IS_LOADING_AUTOCALLS: state => state.isLoadingAutocalls,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
