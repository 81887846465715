import httpAutocaller from '../../plugins/httpAutocaller';

const state = {
  numbers: [],
};

const mutations = {
  SET_NUMBERS(state, { baseId, list, quantity, loadedPages }) {
    const hasList = ~state.numbers.findIndex(n => +n.baseId === +baseId);

    if (hasList) {
      if (loadedPages > 1) {
        state.numbers = state.numbers.map(n => (
          +n.baseId === +baseId
            ? { ...n, list: [...n.list, ...list], loadedPages }
            : n
        ));
      } else {
        state.numbers = state.numbers.map(n => (+n.baseId === +baseId ? { ...n, list, loadedPages } : n));
      }
    } else {
      state.numbers.push({ baseId, list, quantity, loadedPages });
    }
  },
  UNSET_NUMBERS(state, baseId) {
    state.numbers = state.numbers.filter(n => n.baseId !== baseId);
  },
  ADD_NUMBER(state, { baseId, number }) {
    state.numbers = state.numbers.map(ns => (ns.baseId === baseId ? { ...ns, list: [...ns.list, number] } : ns));
  },
  DELETE_NUMBER(state, { baseId, numberId }) {
    const indexBase = state.numbers.findIndex(ns => ns.baseId === baseId);

    if (~indexBase) {
      state.numbers[indexBase].list = state.numbers[indexBase].list.filter(number => number.id !== numberId);
    }
  },
  SET_ALL_FIELDS(state, { baseId, numberId, fields }) {
    const indexBase = state.numbers.findIndex(ns => ns.baseId === baseId);
    const indexNumber = state.numbers[indexBase].list.findIndex(n => n.id === numberId);

    state.numbers[indexBase].list[indexNumber].fields = fields;
  },
  ADD_FIELD(state, { baseId, numberId, field }) {
    const indexBase = state.numbers.findIndex(ns => ns.baseId === baseId);
    const indexNumber = state.numbers[indexBase].list.findIndex(n => n.id === numberId);

    state.numbers[indexBase].list[indexNumber].fields.push(field);
  },
  DELETE_FIELD(state, { baseId, numberId, fieldId }) {
    const indexBase = state.numbers.findIndex(ns => ns.baseId === baseId);
    const indexNumber = state.numbers[indexBase].list.findIndex(n => n.id === numberId);

    state.numbers[indexBase].list[indexNumber].fields.filter(f => (f.id !== fieldId));
  },
  UPDATE_FIELD(state, { baseId, numberId, field }) {
    const indexBase = state.numbers.findIndex(ns => ns.baseId === baseId);
    const indexNumber = state.numbers[indexBase].list.findIndex(n => n.id === numberId);

    state.numbers[indexBase].list[indexNumber].fields = state
      .numbers[indexBase].list[indexNumber].fields.map(f => ((f.id === field.id) ? field : f));
  },
};

const actions = {
  /**
   * Добавление списка номеров в базу
   * @method ADD_NUMBERS_IN_BASE
   * @param {Vuex} store
   * @param {number} _
   * @param {number} _.baseId - ID базы
   * @param {string} _.numbers - Строка с номерами телефонов, в формате "89233541101,89607590808"
   */
  ADD_NUMBERS_IN_BASE(store, { baseId, numbers }) {
    try {
      httpAutocaller.post(`/bases/${baseId}/add_list_of_numbers/`, {
        num: numbers,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Добавление номера с полями в базу
   * @method
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.baseId - ID базы
   * @param {string} _.number - Номер телефона
   * @param {string} _.fields - Массив объектов дополнительных полей с двумя свойствами "name" и "value"
   */
  async ADD_NUMBER_WITH_FIELDS_IN_BASE({ commit }, { baseId, number, fields }) {
    try {
      const { data } = await httpAutocaller.post(`/bases/${baseId}/numbers/`, {
        num: number,
        fields,
      });

      commit('ADD_NUMBER', { baseId, number: data });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получение списка номеров из базы
   * @method GET_NUMBERS_FROM_BASE
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.baseId - ID базы
   * @param {number} _.page - номер страницы
   */
  async GET_NUMBERS_FROM_BASE({ commit }, { baseId, page = 1 }) {
    try {
      const query = page > 1 ? `?page=${page}` : '';

      const { data: { count, results } } = await httpAutocaller.get(`/bases/${baseId}/numbers/${query}`);

      commit('SET_NUMBERS', { baseId, list: results, quantity: count, loadedPages: page });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Удаление номера из базы
   * @method DELETE_NUMBER_FROM_BASE
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.baseId - ID базы
   * @param {number} _.numberId - ID номера телефона
   */
  async DELETE_NUMBER_FROM_BASE({ commit }, { baseId, numberId }) {
    try {
      const { status } = await httpAutocaller.delete(`/bases/${baseId}/numbers/${numberId}/`);

      if (status === 204) {
        commit('DELETE_NUMBER', { baseId, numberId });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получение информации по номеру из базы
   * @method GET_INFO_ABOUT_NUMBER
   */
  GET_INFO_ABOUT_NUMBER() { },

  /**
   * Получение информации по доп. полям номера из базы
   * @method GET_FIELDS_NUMBER
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.baseId - ID базы
   * @param {number} _.numberId - ID номера телефона
   */
  async GET_FIELDS_NUMBER({ commit }, { baseId, numberId }) {
    try {
      const { data: { results } } = await httpAutocaller.get(`/bases/${baseId}/numbers/${numberId}/fields/`);

      commit('SET_ALL_FIELDS', { baseId, numberId, fields: results });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Добавление доп. полей к номеру из базы
   * @method ADD_FIELDS_FOR_NUMBER
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.baseId - ID базы
   * @param {number} _.numberId - ID номера телефона
   * @param {Object} _.field - Дополнительное поле
   * @param {string} _.field.name - Имя дополнительного поля
   * @param {string} _.field.value - Значение дополнительного поля
   */
  async ADD_FIELDS_FOR_NUMBER({ commit }, { baseId, numberId, field: { name, value } }) {
    try {
      const { data } = await httpAutocaller.post(`/bases/${baseId}/numbers/${numberId}/fields/`, { name, value });

      commit('ADD_FIELD', { baseId, numberId, field: data });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Удаление доп. поля по номеру из базы
   * @method DELETE_FIELD_IN_NUMBER
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.baseId - ID базы
   * @param {number} _.numberId - ID номера телефона
   * @param {number} _.fieldId - ID дополнительного поля
   */
  async DELETE_FIELD_IN_NUMBER({ commit }, { baseId, numberId, fieldId }) {
    try {
      const { status } = await httpAutocaller.delete(`/bases/${baseId}/numbers/${numberId}/fields/${fieldId}/`);

      if (status === 204) {
        commit('DELETE_FIELD', { baseId, numberId, fieldId });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Обновление данных в доп. поле по номеру из базы
   * @method UPDATE_FIELD_IN_NUMBER
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.baseId - ID базы
   * @param {number} _.numberId - ID номера телефона
   * @param {Object} _.field - Дополнительное поле
   * @param {number} _.field.id - ID дополнительного поля
   * @param {string} _.field.name - Имя дополнительного поля
   * @param {string} _.field.value - Значение дополнительного поля
   */
  async UPDATE_FIELD_IN_NUMBER({ commit }, { baseId, numberId, field: { id, name, value } }) {
    try {
      const { data } = await httpAutocaller.put(`/bases/${baseId}/numbers/${numberId}/fields/${id}/`, { name, value });

      commit('UPDATE_FIELD', { baseId, numberId, field: data });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Поиск номера по базе
   * @method SEARCH_NUMBER_IN_BASE
   */
  SEARCH_NUMBER_IN_BASE() { },
};

const getters = {
  NUMBERS: state => state.numbers,
  TOTAL_ALL_NUMBERS: state => state.numbers.reduce((total, n) => total + n.quantity, 0),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
