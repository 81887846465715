import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
import ru from 'vuetify/lib/locale/ru';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const opts = {
  lang: {
    locales: { ru },
    current: 'ru',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#fe4f1a',
        accent: '#008cf0',
        hemp: '#26a33b',
        tomato: '#0096d9',
        warning: colors.yellow.darken4,
        background: 'linear-gradient(61deg, #f9f9f9, #f4f4f4)',
      },
      dark: {
        primary: '#fe4f1a',
        accent: '#008cf0',
        hemp: '#26a33b',
        tomato: '#0096d9',
        warning: colors.yellow.darken4,
        background: '#272727',
      },
    },
    // dark: true,
  },
};

export default new Vuetify(opts);
