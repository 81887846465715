import httpAutocaller from '../../../plugins/httpAutocaller';

const state = {
  ivrTts: [],

  ivrTtsExitList: {
    SELF: { id: 'SELF', name: 'self', label: 'Повтор по кругу IVR' },
    HANGUP: { id: 'HANGUP', name: 'hangup', label: 'Положить трубку после времени ожидания' },
    NUMBER: { id: 'NUMBER', name: '', label: 'Внутренний номер' },
  },
};

const mutations = {
  ADD_IVR_TTS(state, ivrTts) {
    state.ivrTts.push(ivrTts);
  },
  UPDATE_IVR_TTS(state, ivrTts) {
    state.ivrTts = state.ivrTts.map(item => (item.id === ivrTts.id ? ivrTts : item));
  },
  DELETE_IVR_TTS(state, ivrTtsId) {
    state.ivrTts = state.ivrTts.filter(({ id }) => id !== ivrTtsId);
  },
};

const actions = {
  /**
   * Создание IVR_TTS
   * @method CREATE_IVR_TTS
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {Object} _.ivrTts - IVR_TTS
   * @param {string} _.ivrTts.ivrExit - Куда будет выходить IVR (self - зацикливание, hangup - сброс после таймаута, {number} - номер ivr)
   * @param {number} _.ivrTts.ivrTimeout - время в секундах сколько IVR будет ждать ввода от пользователя до выполнения выхода
   * @param {Object} _.ivrTts.ivrOptions - Словарь где ключом выступает номер выбора, а значением является номер куда уйти
   * @param {string} _.ivrTts.phrase - Фраза для генерации TTS
   * @param {string} _.ivrTts.voice - Голос для генерации TTS
   * @param {string} _.ivrTts.emotion - Эмоция для генерации TTS
   */
  async CREATE_IVR_TTS({ commit }, { groupId, autocallId, ivrTts }) {
    try {
      const { data } = await httpAutocaller.post(`/groups/${groupId}/auto_call/${autocallId}/smart_tts/`, { ...ivrTts });

      if (data) {
        commit('ADD_IVR_TTS', data);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Получение IVR_TTS
   * @method GET_IVR_TTS
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @return {Object} - IVR_TTS
   */
  async GET_IVR_TTS({ commit, getters }, { groupId, autocallId }) {
    try {
      if (getters.IVR_TTS_BY_AUTOCALL_ID(autocallId)) {
        return getters.IVR_TTS_BY_AUTOCALL_ID(autocallId);
      }

      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/smart_tts/`);

      if (data.results.length) {
        commit('ADD_IVR_TTS', data.results[data.results.length - 1]);

        return data.results[data.results.length - 1];
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Обновление IVR_TTS
   * @method UPDATE_IVR_TTS
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {Object} _.ivrTts - IVR_TTS
   * @param {number} _.ivrTts.id - ID IVR_TTS
   * @param {string} _.ivrTts.ivrExit - Куда будет выходить IVR (self - зацикливание, hangup - сброс после таймаута, {number} - номер ivr)
   * @param {number} _.ivrTts.ivrTimeout - время в секундах сколько IVR будет ждать ввода от пользователя до выполнения выхода
   * @param {Object} _.ivrTts.ivrOptions - Словарь где ключом выступает номер выбора, а значением является номер куда уйти
   * @param {string} _.ivrTts.phrase - Фраза для генерации TTS
   * @param {string} _.ivrTts.voice - Голос для генерации TTS
   * @param {string} _.ivrTts.emotion - Эмоция для генерации TTS
   */
  async UPDATE_IVR_TTS({ commit }, { groupId, autocallId, ivrTts }) {
    try {
      const { data } = await httpAutocaller.put(`/groups/${groupId}/auto_call/${autocallId}/smart_tts/${ivrTts.id}/`, { ...ivrTts });

      if (data) {
        commit('UPDATE_IVR_TTS', data);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  /**
   * Удаление IVR_TTS
   * @method DELETE_IVR_TTS
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.ivrTtsId - ID IVR_TTS
   */
  async DELETE_IVR_TTS({ commit }, { groupId, autocallId, ivrTtsId }) {
    try {
      const { status } = await httpAutocaller.delete(`/groups/${groupId}/auto_call/${autocallId}/smart_tts/${ivrTtsId}/`);

      if (status === 204) {
        commit('DELETE_IVR_TTS', ivrTtsId);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  IVR_TTS_BY_AUTOCALL_ID: state => id => state.ivrTts.find(({ calling }) => calling === id),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
