import Vue from 'vue';
import axios from 'axios';
import { decamelize } from 'humps';
import fileDownload from 'js-file-download';
import httpAutocaller from '../../plugins/httpAutocaller';

const TYPES = {
  GENERAL: 'general',
  SMS: 'sms',
};

const state = {
  statistics: {},
  statisticTypes: { ...TYPES },
};

const mutations = {
  SET_STATISTIC(state, { autocallId, type, statistic, quantityNumbers, loadedPages }) {
    if (type === 'numbers' && state.statistics[autocallId].numbers) {
      Vue.set(state.statistics, autocallId, { ...state.statistics[autocallId], [type]: statistic });
    } else {
      Vue.set(state.statistics, autocallId, { ...state.statistics[autocallId], [type]: statistic });
    }

    if (quantityNumbers) {
      Vue.set(state.statistics[autocallId], 'quantityNumbers', quantityNumbers);
    }

    if (loadedPages) {
      Vue.set(state.statistics[autocallId], 'loadedPages', loadedPages);
    }
  },
  SET_PROGRESS(state, { autocallId, progress }) {
    Vue.set(state.statistics, autocallId, { ...state.statistics[autocallId], progress });
  },
  UPDATE_STATUS(state, { autocallId, status }) {
    Vue.set(state.statistics[autocallId].progress, status, status);
  },
};

const actions = {
  /**
   * Получить список статистики по рассылке
   * @method GET_STATISTIC
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @param {number?} _.type - GENERAL - общая, AVERAGE_TIME - средняя длительность прослушивания, BUTTONS_PRESSED - нажатые кнопки
   */
  async GET_STATISTIC({ commit }, { groupId, autocallId, type }) {
    try {
      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/statistics/${decamelize(type)}/`);
      commit('SET_STATISTIC', { autocallId, type, statistic: data });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получить список статистики по номерам рассылки
   * @method GET_NUMBERS_STATISTIC
   * @param {Vuex} store
   * @param {Object} autocall - Рассылка
   * @param {number} autocall.groupId - ID группы рассылки
   * @param {number} autocall.autocallId - ID рассылки
   * @param {number} autocall.page - Страница в пагинации
   */
  async GET_NUMBERS_STATISTIC({ commit }, { groupId, autocallId }) {
    try {
      const stat = [];
      const promises = [];

      const { data: { results, count } } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/stats/`);
      stat.push(...results);

      for (let i = 2; i < (count / 100) + 1; i++) {
        promises.push(httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/stats/?page=${i}`));
      }

      Promise.all(promises).then(responses => responses.forEach(response => {
        const { data: { results } } = response;
        stat.push(...results);
      }));

      commit('SET_STATISTIC', { autocallId, type: 'numbers', statistic: stat, quantityNumbers: count, loadedPages: 999 });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Скачать excel файл со статистикой рассылки
   * @method DOWNLOAD_STATISTIC_AS_EXCEL
   * @param {Vuex} store
   * @param {Object} autocall - Рассылка
   * @param {number} autocall.groupId - ID группы рассылки
   * @param {number} autocall.autocallId - ID рассылки
   * @returns {Object} ulr - ссылка на файл и name - название файла
   */
  async DOWNLOAD_STATISTIC_AS_EXCEL(store, { groupId, autocallId }) {
    try {
      const { data, headers } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/excel/`, {
        responseType: 'blob',
        transformResponse: [
          ...axios.defaults.transformResponse,
          data => data,
        ],
      });

      console.log(headers);

      const fileName = 'Stat.xlsx';

      // const [, fileName] = headers['content-disposition'].match(/filename="(.*?)"/);

      fileDownload(data, fileName);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получить список критериев статистики по рассылке
   * @method GET_LIST_CRITERIES
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @returns {Object} Критерий
   */
  async GET_LIST_CRITERIES(store, { groupId, autocallId }) {
    try {
      const { data: { results } } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/criteria/`);

      return results[0] ?? {};
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Создать критерии статистики
   * @method CREATE_CRITERION
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @param {Object} _.criteria - Критерий
   * @param {number} [_.criteria.maxBillsec=''] - Длительность вызова
   * @param {string} [_.criteria.digits=''] - Нажатая кнопка в голосовом меню
   * @param {string} [_.criteria.ivrStatus=0] - Статус дозвона
   */
  async CREATE_CRITERION(store, { groupId, autocallId, criteria: { maxBillsec = 2, digits = '', ivrStatus = '' } }) {
    try {
      const { data } = await httpAutocaller.post(`/groups/${groupId}/auto_call/${autocallId}/criteria/`, {
        maxBillsec,
        digits,
        ivrStatus,
      });

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получить настройки критерия
   * @method GET_SETTINGS_CRITERION
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   */
  async GET_SETTINGS_CRITERION(store, { groupId, autocallId, criterionId }) {
    try {
      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/criteria/${criterionId}/`);

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Обновить настройки критериев
   * @method UPDATE_SETTINGS_CRITERION
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @param {Object} _.criteria - Критерий
   * @param {number} _.criteria.id - ID критерия
   * @param {number} _.criteria.maxBillsec - Длительность вызова
   * @param {string} _.criteria.digits - Нажатая кнопка в голосовом меню
   * @param {string} _.criteria.ivrStatus - Статус дозвона
   */
  async UPDATE_SETTINGS_CRITERION(store, { groupId, autocallId, criteria }) {
    try {
      const { data } = await httpAutocaller.put(`/groups/${groupId}/auto_call/${autocallId}/criteria/${criteria.id}/`, {
        ...criteria,
      });

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Удалить критерии
   * @method DELETE_CRITERION
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.criterionId - ID критерия
   */
  async DELETE_CRITERION(store, { groupId, autocallId, criterionId }) {
    try {
      await httpAutocaller.delete(`/groups/${groupId}/auto_call/${autocallId}/criteria/${criterionId}/`);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получить прогресс рассылки
   * @method GET_AUTOCALL_PROGRESS
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылки
   * @param {number} _.autocallId - ID рассылки
   * @returns {object} - Статус; общее кол-во номеров; кол-во номеров, по которым прошел обзвон
   */
  async GET_AUTOCALL_PROGRESS({ commit }, { groupId, autocallId }) {
    try {
      const { data } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/progress/`);

      commit('SET_PROGRESS', { autocallId, progress: data });

      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
 * Получить прогресс всех кампаний
 * @method GET_ALL_AUTOCALL_PROGRESS
 * @param {Vuex} store
 * @param {Object} _
 * @returns {object}
 */
  async GET_ALL_AUTOCALL_PROGRESS({ commit }) {
    try {
      const { data } = await httpAutocaller.get('/get_company_status/');
      data.forEach((item) => {
        const autocallId = item.id;
        commit('SET_PROGRESS', { autocallId, progress: item });
      });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  STATISTICS: state => state.statistics,
  GET_PROGRESS: state => autocallId => state.statistics[autocallId]?.progress,
  STATISTIC_BY_AUTOCALL_ID: state => id => state.statistics[id],
  STATISTIC_TYPES: state => state.statisticTypes,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
