import httpBilling from '../../plugins/httpBilling';

const state = {
  loadingAutocallInfo: true,
  autocallInfo: [],
};

const mutations = {
  SET_AUTOCALL_INFO(state, autocallInfo) {
    state.autocallInfo = autocallInfo.split('|');
  },
  SET_LOADING_AUTOCALL_INFO(state, isLoading) {
    state.loadingAutocallInfo = isLoading;
  },
};

const actions = {
  /**
   * Получение остатков пакета
   * @method GET_AUTOCALL_INFO
   * @param {Vuex} store
   */
  async GET_AUTOCALL_INFO({ commit, getters }) {
    try {
      const { data } = await httpBilling.get(
        '/',
        {
          params: {
            mission: 'GetAutodialingInfo',
            pin: getters.PIN,
          },
        },
      );

      if (data === 'Service not found') {
        return;
      }

      commit('SET_AUTOCALL_INFO', data);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      commit('SET_LOADING_AUTOCALL_INFO', false);
    }
  },
};

const getters = {
  AUTOCALL_INFO: state => state.autocallInfo,
  AUTOCALL_SPENT_MINUTES: state => state.autocallInfo[0] || '',
  AUTOCALL_TOTAL_MINUTES: state => state.autocallInfo[1] || '',
  AUTOCALL_LOST_MINUTES: state => state.autocallInfo[2] || '',
  AUTOCALL_COST_PACKAGE: state => state.autocallInfo[3] || '',
  AUTOCALL_LOCK_INTERCITY: state => state.autocallInfo[4] || '',
  AUTOCALL_LOCK_SUPER_PACKAGE: state => state.autocallInfo[5] || '',
  AUTOCALL_DATE_START: state => state.autocallInfo[6] || '',
  AUTOCALL_DATE_END: state => state.autocallInfo[7] || '',
  AUTOCALL_PACKAGE_NAME: state => state.autocallInfo[8] || '',
  AUTOCALL_TARIFF_NAME: state => state.autocallInfo[9] || '',
};

export default {
  state,
  mutations,
  actions,
  getters,
};
