import httpAutocaller from '../../../plugins/httpAutocaller';

// не спрашивайте что это, никто не знает, но часть запросов с апи телезона должны быть с этой штукой, а именно ivr
// возможно когда то они руками поменяют этот хеш, но никто не знает когда и почему, тогда все посыпется
// const PREFIX = '60664362-a2ae-4def-b541-cb0cf5cb4350';

const state = {
  ivrSettings: null,
  ivrTargets: [],
};

const mutations = {
  SET_IVR_SETTINGS(state, settings) {
    state.ivrSettings = settings;
  },
  SET_IVR_TARGETS(state, targets) {
    state.ivrTargets = targets.map(({ name, objects }) => ({
      name,
      list: objects
        .map(({ name, number }) => ({ name, number })),
    }));
  },
};

const actions = {
  // /**
  //  * Запрос настроек IVR
  //  * @method GET_IVR_SETTINGS
  //  * @param {Vuex} store
  //  */
  // async GET_IVR_SETTINGS({ commit, getters }) {
  //   try {
  //     if (getters.IVR_SETTINGS) {
  //       return getters.IVR_SETTINGS;
  //     }

  //     const { data } = await httpTelezon.post(`/${PREFIX}/ivr.get_settings`, {
  //       sessionUuid: getters.UUID,
  //       embedded: false,
  //     });

  //     if (data.result) {
  //       commit('SET_IVR_SETTINGS', data.result);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     throw e;
  //   }
  // },
  /**
   * Запрос внутренних номеров IVR
   * @method GET_TARGET_NUMBERS
   * @param {Vuex} store
   */
  async GET_TARGET_NUMBERS({ commit, getters }) {
    try {
      if (getters.INTERNAL_IVR_NUMBERS.length) {
        return getters.INTERNAL_IVR_NUMBERS;
      }

      const { data } = await httpAutocaller.post('/get_target_numbers/', {
        pin: getters.PIN,
      });

      if (data) {
        commit('SET_IVR_TARGETS', data);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  IVR_UUID: state => (state.ivrSettings ? state.ivrSettings.uuid : null),
  IVR_SETTINGS: state => state.ivrSettings,
  INTERNAL_IVR_NUMBERS: state => state.ivrTargets,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
