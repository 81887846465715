import axios from 'axios';
import humps from 'humps';
import store from '../store';
import { urlApiAutocaller } from '../config';
import { getUrlSearchParams } from '../helpers';

const urlSearchParams = humps.camelizeKeys(getUrlSearchParams());
const { pin } = urlSearchParams;
const token = urlSearchParams?.token || localStorage.getItem('token') || '';

if (token && localStorage.getItem('token') !== token) {
  localStorage.setItem('token', token);
}

const instance = axios.create({
  baseURL: urlApiAutocaller,
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data, (key, convert) => convert(key.replace(' ', '_'))),
  ],
  transformRequest: [
    data => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest,
  ],
  headers: {
    PIN: pin,
    AUTHORIZATION: token,
  },
});

export default instance;

instance.interceptors.response.use(response => response, error => {
  if (error.response.status === 403) {
    store.commit('DENY_ACCESS');
  }

  return Promise.reject(data);
});
// Судя по всему этот метод используется для автоматической авторизации, но это уже устарело
// } else {
//   let isRefreshing = false;
//   let failedQueue = [];

//   const processQueue = (error, token = null) => {
//     failedQueue.forEach(prom => {
//       if (error) {
//         prom.reject(error);
//       } else {
//         prom.resolve(token);
//       }
//     });

//     failedQueue = [];
//   };

//   instance.interceptors.response.use(response => response, error => {
//     const originalRequest = error.config;
//     const { response: { data } } = error;

//     if (error.response.status === 403 && !originalRequest._retry) {
//       if (isRefreshing) {
//         return new Promise((resolve, reject) => {
//           failedQueue.push({
//             resolve,
//             reject,
//           });
//         }).then(uuid => {
//           originalRequest.headers['API-UUID'] = uuid;
//           return axios(originalRequest);
//         }).catch(err => err);
//       }

//       /* eslint no-underscore-dangle: "off" */
//       originalRequest._retry = true;
//       isRefreshing = true;

//       const { PIN } = store.getters;

//       return new Promise((resolve, reject) => {
//         axios({
//           method: 'post',
//           url: `${urlApiTelezon}/d083b0f2f81a41fe96a6/authorization.session_open`,
//           data: { pin: PIN },
//         })
//           .then(({ data }) => {
//             const { sessionUuid } = humps.camelizeKeys(data.result);

//             store.commit('SET_UUID', sessionUuid);
//             originalRequest.headers['API-UUID'] = sessionUuid;
//             processQueue(null, sessionUuid);
//             resolve(axios(originalRequest));
//           })
//           .catch(err => {
//             processQueue(err, null);
//             reject(err);
//             // logout
//           })
//           .then(() => {
//             isRefreshing = false;
//           });
//       });
//     }

//     return Promise.reject(data);
//   });
// }
