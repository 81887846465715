<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-main>
      <AppHeader />

      <v-container>
        <v-row>
          <v-col cols="12" xl="10" offset-xl="1">
            <router-view />
          </v-col>
        </v-row>
      </v-container>

      <v-btn
        fixed bottom right
        fab large dark
        color="grey darken-3"
        :class="['toggler-theme', {'is-theme-dark': $vuetify.theme.dark}]"
        @click="$vuetify.theme.dark = !$vuetify.theme.dark"
      >
        <v-icon>mdi-weather-night</v-icon>
      </v-btn>
    </v-main>
    <notifications position="top" :duration="10000" class="notifications">
      <template slot="body" slot-scope="{ item, close }">
        <v-alert border="left" :type="item.type">
          <v-row align="center">
            <v-col class="grow">
              <p v-if="item.title" class="text-subtitle-1 mb-1">
                {{ item.title }}
              </p>
              <div class="text-body-2" v-html="item.text" />
            </v-col>
            <v-col class="shrink">
              <v-btn icon fab x-small @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </template>
    </notifications>

    <!-- <DialogAccessForbidden /> -->
    <DialogDevTime />
  </v-app>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
// import DialogAccessForbidden from './components/dialogs/DialogAccessForbidden.vue';
import DialogDevTime from './components/dialogs/DialogDevTime.vue';

export default {
  name: 'App',
  components: { AppHeader, DialogDevTime },
  computed: {
    theme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Arial, Helvetica, sans-serif;
  // transform: scale(0.875);
  // transform-origin: center;
  // @font-face {
  //   font-family: 'PF BeauSans Pro';
  //   src: local("PF BeauSans Pro");
  //   src: url('./fonts/PF-BeauSans-Pro/subset-PFBeauSansPro-Light.woff2') format('woff2'),
  //     url('./fonts/PF-BeauSans-Pro/subset-PFBeauSansPro-Light.woff') format('woff');
  //   font-weight: 300;
  //   font-style: normal;
  //   font-display: swap;
  // }
  // @font-face {
  //   font-family: 'PF BeauSans Pro Semibold';
  //   src: local("PF BeauSans Pro Semibold");
  //   src: url('./fonts/PF-BeauSans-Pro-Semibold/subset-PFBeauSansPro-SemiBoldItalic.woff2') format('woff2'),
  //     url('./fonts/PF-BeauSans-Pro-Semibold/subset-PFBeauSansPro-SemiBoldItalic.woff') format('woff');
  //   font-weight: 600;
  //   font-style: italic;
  //   font-display: swap;
  // }
  // @font-face {
  //     font-family: 'PF BeauSans Pro Bold';
  //     src: local("PF BeauSans Pro Bold");
  //     src: url('./fonts/PF-BeauSans-Pro-Bold/subset-PFBeauSansPro-Bold.woff2') format('woff2'),
  //         url('./fonts/PF-BeauSans-Pro-Bold/subset-PFBeauSansPro-Bold.woff') format('woff');
  //     font-weight: bold;
  //     font-style: normal;
  //     font-display: swap;
  // }
}

.toggler-theme.is-theme-dark::after {
  content: "";
  height: 2px;
  width: 70%;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.v-btn{
  border-radius:10px!important;
}

$data-table-regular-header-font-size: "12px";
$data-table-regular-row-font-size: "12px";

</style>
