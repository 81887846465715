import Vue from 'vue';
import httpAutocaller from '../../plugins/httpAutocaller';

const state = {
  connectedBaseIds: { },
};

const mutations = {
  ADD_RELATION_BASE_IN_AUTOCALL(state, { autocallId, baseIds }) {
    const bases = state.connectedBaseIds[autocallId] || [];

    if (typeof baseIds === 'number') {
      Vue.set(state.connectedBaseIds, autocallId, Array.from(new Set([...bases, baseIds])));
    } else {
      Vue.set(state.connectedBaseIds, autocallId, Array.from(new Set([...bases, ...baseIds])));
    }
  },
  REMOVE_RELATION_BASE_IN_AUTOCALL(state, { autocallId, baseIds }) {
    debugger;
    if (typeof baseIds === 'number') {
      const bases = state.connectedBaseIds[autocallId].filter(id => id !== baseId);

      Vue.set(state.connectedBaseIds, autocallId, bases);
    } else {
      const bases = state.connectedBaseIds[autocallId].filter(id => !baseIds.includes(id));

      Vue.set(state.connectedBaseIds, autocallId, bases);
    }
  },
};

const actions = {
  /**
   * Получение списка подключенных баз к рассылке
   * @method GET_BASES_IN_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @returns {Array} Массив баз
   */
  async GET_BASES_IN_AUTOCALL({ commit, getters }, { groupId, autocallId }) {
    try {
      if (getters.CONNECTED_BASE_IDS[autocallId]) {
        return getters.CONNECTED_BASE_IDS[autocallId];
      }

      const { data: { results } } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/call_bases/`);

      commit('ADD_RELATION_BASE_IN_AUTOCALL', { autocallId, baseIds: results.map(b => b.id) });

      return results;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Создание базы номеров подключение к рассылке
   * @method CREATE_BASE_IN_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {string} _.name - Название базы
   * @param {string} _.comment - Комментарий к базе
   */
  async CREATE_BASE_IN_AUTOCALL({ commit }, {
    groupId, autocallId, name, comment,
  }) {
    try {
      const { data } = await httpAutocaller.post(`/groups/${groupId}/auto_call/${autocallId}/call_bases/`, { name, comment });

      commit('ADD_RELATION_BASE_IN_AUTOCALL', { autocallId, baseIds: data.id });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Добавление ранее созданной базы к рассылке
   * @method ADD_BASE_IN_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.baseId - ID базы номеров
   */
  async ADD_BASE_IN_AUTOCALL({ commit }, { groupId, autocallId, baseId }) {
    try {
      await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/add_base/${baseId}`);

      commit('ADD_RELATION_BASE_IN_AUTOCALL', { autocallId, baseIds: baseId });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Получение настроек базы, подключенной к рассылке
   * @method GET_INFORMATION_BASE_IN_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.baseId - ID базы номеров
   * @returns {Object} Объект с информацией по базе
   */
  async GET_INFORMATION_BASE_IN_AUTOCALL(store, { groupId, autocallId, baseId }) {
    try {
      const { data: { results } } = await httpAutocaller.get(`/groups/${groupId}/auto_call/${autocallId}/call_bases/${baseId}/`);

      return results;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Обновление настроек базы, подключенной к рассылке
   * @method UPDATE_BASE_IN_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.baseId - ID базы номеров
   * @param {string} _.name - Название базы
   * @param {string} _.comment - Комментарий к базе
   */
  async UPDATE_BASE_IN_AUTOCALL(store, {
    groupId, autocallId, baseId, name, comment,
  }) {
    try {
      const { data: { results } } = await httpAutocaller.put(`/groups/${groupId}/auto_call/${autocallId}/call_bases/${baseId}/`, { name, comment });

      return results;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Удаление базы, подключенной к рассылке
   * @method DELETE_BASE_IN_AUTOCALL
   * @param {Vuex} store
   * @param {Object} _
   * @param {number} _.groupId - ID группы рассылок
   * @param {number} _.autocallId - ID рассылки
   * @param {number} _.baseId - ID базы номеров
   */
  async DELETE_BASE_IN_AUTOCALL({ commit }, { groupId, autocallId, baseId }) {
    try {
      const { status } = await httpAutocaller.delete(`/groups/${groupId}/auto_call/${autocallId}/call_bases/${baseId}/`);

      if (status === 204) {
        commit('REMOVE_RELATION_BASE_IN_AUTOCALL', { autocallId, baseIds: baseId });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

const getters = {
  CONNECTED_BASE_IDS: state => state.connectedBaseIds,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
