const PROXIED = false;

export const urlTelezonLk = PROXIED ? '/api/telezon' : 'https://cabinet.telezon.ru:8001';
export const urlApiBilling = PROXIED ? '/api/billing' : 'https://devabacs.telezon.ru:1137';
// export const urlApiBilling = PROXIED ? '/api/billing' : 'https://devabacs2.telezon.ru:1138';
// export const urlApiAutocaller = PROXIED ? '/api/autocaller' : 'http://0.0.0.0:8020/api';
// export const urlApiAutocaller = PROXIED ? '/api/autocaller' : 'http://192.168.3.144:8020/api';
export const urlApiAutocaller = PROXIED ? '/api/autocaller' : 'https://api.telezon.ru/cbc/api';

// ms
export const DELAY_CYCLE_UPDATE_PROGRESS = 5000;
export const DELAY_CYCLE_UPDATE_DEVTIME = 10000;
// 30m
export const DELAY_CYCLE_UPDATE_AUTH = 1800000;
